import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide, RadioGroup, Radio } from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import { NavButton } from "../../../Components/Buttons";
import  CustomTextField from "../../../Components/CustomTextField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { appColors, appFonts, useAppTheme, appDialogTransparentPaperProps } from "../../../theme";
import Header from "../../../Components/Header";
import FormErrorBanner from "../../../Components/Errors/FormErrorBanner";
import ProgressStepper from "../../../Components/ProgressStepper";
import {
  gotoNextTaskStep,
  gotoPrevTaskStep,
  setTaskTransitionPending,
} from "../../../features/task/taskSlice";
import Aem, { AemContentTypes } from "../../../lib/aem/components/Aem";
import { BackButton } from "../../../Components/Buttons";
import FormLabel from "@mui/material/FormLabel";
import {
  updateDemographics,
  Demographics,
} from "../../../features/patient/patientSlice";
import { UpdatePatientDemographicsAPI } from "../../../api/UpdateFhirAPI";
import {
  SexAssignedBirthList,
  GenderIdentityList,
  PreferredPronounsList,
  MaritalStatusList,
  RaceList,
  EthnicityList,
  ReligiousAffiliationKVPairs,
} from "../../../data/demographicsData";
import OverlayComponent from "../../../Components/Overlay/Overlay";
import { AemKVPair } from "../../../lib/aem";
import { AriaLive } from "../../../data/accessibility/AccessibilityData";


const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  headerTitle: {
    marginTop: "0px",
  },
  navSection: {
    paddingBottom: "24px",
    marginTop: "24px",
  },
  sectionContent: {
    fontSize: "18px",
    fontFamily: appFonts.medium,
  },
  sectionContent2: {
    fontSize: "18px",
    fontFamily: appFonts.medium,
    color: "#ff612b !important",
  },
  selectedOption: {
    marginTop: "4px",
    backgroundColor: "white",
    height: "48px",
    borderRadius: "24px",
    display: "flex",
    justifyItems: "center",
  },
  unselectedOption: {
    marginTop: "4px",
    backgroundColor: "rgba(0,0,0,0.2)",
    color: "white",
    height: "48px",
    border: "1px solid rgba(255,255,255,0.15)",
    borderRadius: "24px",
    display: "flex",
    justifyItems: "center",
  },
  nameLabel: {
    minHeight: "22px",
    marginTop:"20px",
    marginBottom: "5px",
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
  },
  nameValue: {
    minHeight: "22px",
    marginBottom: "5px",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    lineHeight: "22px",
  },
  selectedLabel: {
    color: "#222222",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
  },
  unselectedLabel: {
    color: appColors.white,
    fontFamily: appFonts.regular,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
  },
  radioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
    },
    color: "white",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: appColors.blueMid,
    },
  },
  radioHeadings: {
    fontFamily: appFonts.bold,
    color: appColors.white,
    marginTop: "31px",
    fontSize: "18px",
    marginBottom: "12px",
  },
  radioHeadingsError: {
    color: appColors.darkError,
  },
  errorLabel: {
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "11px",
    backgroundColor: "rgba(240, 89, 115,0.2)",
    marginTop: "5px",
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  inpBox: {
    marginTop: "30px",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    color: "#152831",
    "& .MuiInputLabel-shrink": {
      fontSize: "14px",
      transform: "translate(0, 1.5px)",
    },
    "& .MuiFormLabel-root": {
      fontFamily: appFonts.semibold,
      letterSpacing: 0,
    },
    marginBottom: "13px",
  }
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function PatientDemographicsScreen() {
  const theme = useAppTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const rbSexGroupId = useId();
  const rbSexLabelId = useId();
  const rbSexInputIdPrefix = useId();
  const rbGenderGroupId = useId();
  const rbGenderLabelId = useId();
  const rbGenderInputIdPrefix = useId();
  const rbPrefPronounsGroupId = useId();
  const rbPrefPronounsLabelId = useId();
  const rbPrefPronounsInputIdPrefix = useId();
  const rbMaritalGroupId = useId();
  const rbMaritalLabelId = useId();
  const rbMaritalInputIdPrefix = useId();
  const rbRaceGroupId = useId();
  const rbRaceLabelId = useId();
  const rbRaceInputIdPrefix = useId();
  const rbEthnicityGroupId = useId();
  const rbEthnicityLabelId = useId();
  const rbEthnicityInputIdPrefix = useId();
  const religionInputId = useId();

  const myRef = useRef<null | HTMLDivElement>(null);

  let demographics: Demographics = useAppSelector(
    (state) => state.patient.demographics
  );

  const religoiusAffLabel: string = Aem.get(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_8",
    "Religious Affiliation"
  );

  // call aem lists at the start
  const sexAssignedBirthItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_1",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_1",
    Aem.toKvPairs(SexAssignedBirthList)
  );
  const genderItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_2",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_2",
    Aem.toKvPairs(GenderIdentityList)
  );
  const preferredPronounsItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_3",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_3",
    Aem.toKvPairs(PreferredPronounsList)
  );
  const maritalStatusItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_4",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_4",
    Aem.toKvPairs(MaritalStatusList)
  );
  const raceItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_5",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_5",
    Aem.toKvPairs(RaceList)
  );
  const ethnicityItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_6",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_6",
    Aem.toKvPairs(EthnicityList)
  );
  const religiousItems: AemKVPair[] = Aem.getListKVPairs(
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_CODES_7",
    "BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_LIST_7",
    ReligiousAffiliationKVPairs
  );

  const [sexAtBirth, setSexAtBirth] = useState<string>(
    demographics.sexAtBirth ? demographics.sexAtBirth : ""
  );
  const [sexAtBirthValue, setSexAtBirthValue] = useState<string>(
    sexAtBirth ? Aem.getListKVPairValue(sexAssignedBirthItems, sexAtBirth) : "");
  const [genderIdentity, setGenderIdentity] = useState<string>(
    demographics.genderIdentity ? demographics.genderIdentity : ""
  );
  const [genderIdentityValue, setGenderIdentityValue] = useState<string>(
    genderIdentity ? Aem.getListKVPairValue(genderItems, genderIdentity) : ""
  );
  const [prefPronoun, setPrefPronoun] = useState<string>(
    demographics.preferredPronoun ? demographics.preferredPronoun : ""
  );
  const [prefPronounValue, setPrefPronounValue] = useState<string>(
    prefPronoun ? Aem.getListKVPairValue(preferredPronounsItems, prefPronoun) : ""
  );
  const [religiousAff, setReligiousAff] = useState<string>(
    demographics.religiousAffiliation ? demographics.religiousAffiliation : ""
  );
  const [religiousAffValue, setReligiousAffValue] = useState<string>(
    religiousAff ? Aem.getListKVPairValue(religiousItems, religiousAff) : ""
  );
  const [maritalStatus, setMaritalStatus] = useState<string>(
    demographics.maritalStatus ? demographics.maritalStatus : ""
  );
  const [maritalStatusValue, setMaritalStatusValue] = useState<string>(
    maritalStatus ? Aem.getListKVPairValue(maritalStatusItems, maritalStatus) : ""
  );
  const [race, setRace] = useState<string>(
    demographics.race ? demographics.race : ""
  );
  const [raceValue, setRaceValue] = useState<string>(
    race ? Aem.getListKVPairValue(raceItems, race) : ""
  );
  const [ethnicity, setEthnicity] = useState<string>(
    demographics.ethnicity ? demographics.ethnicity : ""
  );
  const [ethnicityValue, setEthnicityValue] = useState<string>(
    ethnicity ? Aem.getListKVPairValue(ethnicityItems, ethnicity) : ""
  );
  const [overlay, setOverlay] = useState(false);
  const [error, setError] = useState(false);
  const patientId: string = useAppSelector((state) => state.patient.patientId);

  let religionList: string[] = religiousItems.map(ele => ele?.Value || "");

  const addReligion = (v: any) => {
    if (v) {
      let data = Aem.getListKVPairKey(religiousItems, v);
      setReligiousAffValue(v);
      setReligiousAff(data);
    }
    setOverlay(false);
  };

  const updateRedux = () => {
  
    demographics = {
      // codes
      sexAtBirth: sexAtBirth || "",
      genderIdentity: genderIdentity || "",
      preferredPronoun: prefPronoun || "",
      religiousAffiliation: religiousAff || "",
      maritalStatus: maritalStatus || "",
      race: race || "",
      ethnicity: ethnicity || "",
      // values
      sexAtBirthValue: sexAtBirthValue || "",
      genderIdentityValue: genderIdentityValue || "",
      preferredPronounValue: prefPronounValue || "",
      religiousAffiliationValue: religiousAffValue || "",
      maritalStatusValue: maritalStatusValue || "",
      raceValue: raceValue || "",
      ethnicityValue: ethnicityValue || "",
    };
    dispatch(updateDemographics(demographics));
  };

  const updateDetailsAPI = async () => {
    let data: any = {
      patientId,
    };
    
    data.sexAtBirth = {
      system: null,
      code: sexAtBirth,
      display: sexAtBirthValue,
      codingSystemName: null,
    };
    data.genderIdentity = {
      system: null,
      code: genderIdentity,
      display: genderIdentityValue,
      codingSystemName: null,
    };
    data.preferredPronoun = {
      system: null,
      code: prefPronoun,
      display: prefPronounValue,
      codingSystemName: null,
    };
    data.religiousAffiliation = {
      system: null,
      code: religiousAff,
      display: religiousAffValue,
      codingSystemName: null,
    };
    data.maritalStatus = {
      system: null,
      code: maritalStatus,
      display: maritalStatusValue,
      codingSystemName: null,
    };
    data.race = {
      system: null,
      code: race,
      display: raceValue,
      codingSystemName: null,
    };
    data.ethnicity = {
      system: null,
      code: ethnicity,
      display: ethnicityValue,
      codingSystemName: null,
    };
    await UpdatePatientDemographicsAPI(data);
  };

  const [buttonDisabled, setButtonDisabled] = useState(true);
  
  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;
  const navButtonName: string = editMode ? "save": "next";
  const navButtonLabel: string = editMode
    ? Aem.get("ACTION_SAVEBUTTON_TEXT_1", "Save")
    : Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next");

  useEffect(() => {
    if (sexAtBirth !== "") {
      setButtonDisabled(false);
    }
  }, [sexAtBirth]);
  
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={theme.navContent}>
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgGreenGrad)}
          style={{ marginBottom: 0 }}
         >
          <Header showMenuButton={true} />

          {!editMode && <ProgressStepper step={1} totalSteps={4} />}

          <Grid container className={classes.pgFunc}>
          <Grid item>  
            <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
              <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_IDENTIFY_1">
                Patient Demographics
              </Aem>
            </h1>
          </Grid>
          <Grid item>
            {/* <DemoSVG/> */}
            <Aem cid="HEADER_DEMOGRAPHICS_PATIENT_IDENTIFY_LOGO_1" type={AemContentTypes.imageUrl}></Aem>
          </Grid>
          </Grid>
        </Grid>
        {error && <FormErrorBanner aemId={"BODY_SELFIDENTIFY_TEXT_4"} />}
        <Grid
          container
          direction="column"
          className={clsx(theme.navSection, theme.nowrap, classes.navSection)}
        >
          <Grid item className={classes.sectionContent}>
            <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_1">
              Please answer the following questions. Required questions are
              marked with an asterisk (*).
            </Aem>
          </Grid>
          <Grid item className={classes.sectionContent2}>
          <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_10">
            If your Sex Assigned at Birth is incorrect below, please bring documentation to the clinic validating the correct information or call the clinic directly for assistance.
            </Aem>
          </Grid>

          {/* <RadioGroup
            id={rbSexGroupId}
            aria-labelledby={rbSexLabelId}
            name="sex"
            value={sexAtBirth}
          >
            <Grid item id={rbSexLabelId} className={clsx(classes.radioHeadings, { [classes.radioHeadingsError]: error })}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_2">
                Sex Assigned at Birth*
              </Aem>
              {error && (
                <Grid className={classes.errorLabel} aria-live={AriaLive.polite}>
                  <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_9">
                    Please make a selection.
                  </Aem>
                </Grid>
              )}
            </Grid>

            {sexAssignedBirthItems.map((item, idx) => {
              const rbSexInputId = `${rbSexInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  className={
                    sexAtBirth === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbSexInputId}
                    value={item}
                    checked={sexAtBirth === item?.Key}
                    required={true}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbSexInputId}
                    className={
                      sexAtBirth === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup> */}

          <Grid item className={classes.nameLabel}>
            <Aem cid="BODY_FINALREVIEW_TEXT_9">Sex Assigned at Birth</Aem>
          </Grid>
          <Grid item className={classes.nameValue}>
          {demographics.sexAtBirthValue}
          </Grid>
          <RadioGroup
            id={rbGenderGroupId}
            aria-labelledby={rbGenderLabelId}
            name="gender"
            value={genderIdentity}
          >
            <Grid item id={rbGenderLabelId} className={classes.radioHeadings}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_3">
                Gender Identity
              </Aem>
            </Grid>

            {genderItems.map((item, idx) => {
              const rbGenderInputId = `${rbGenderInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  onClick={() => {
                    setGenderIdentity(item.Key);
                    setGenderIdentityValue(item?.Value);
                  }}
                  className={
                    genderIdentity === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbGenderInputId}
                    value={item}
                    checked={genderIdentity === item?.Key}
                    onChange={() => {
                      setGenderIdentity(item?.Key);
                      setGenderIdentityValue(item?.Value);
                    }}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbGenderInputId}
                    className={
                      genderIdentity === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup>

          <RadioGroup
            id={rbPrefPronounsGroupId}
            aria-labelledby={rbPrefPronounsLabelId}
            name="preferredPronouns"
            value={prefPronoun}
          >
            <Grid item id={rbPrefPronounsLabelId} className={classes.radioHeadings}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_4">
                Preferred Pronouns
              </Aem>
            </Grid>

            {preferredPronounsItems.map((item, idx) => {
              const rbPrefPronounsInputId = `${rbPrefPronounsInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  onClick={() => {
                    setPrefPronoun(item.Key);
                    setPrefPronounValue(item?.Value);
                  }}
                  className={
                    prefPronoun === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbPrefPronounsInputId}
                    value={item}
                    checked={prefPronoun === item?.Key}
                    onChange={() => {
                      setPrefPronoun(item?.Key);
                      setPrefPronounValue(item?.Value);
                    }}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbPrefPronounsInputId}
                    className={
                      prefPronoun === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup>

          <RadioGroup
            id={rbMaritalGroupId}
            aria-labelledby={rbMaritalLabelId}
            name="maritalStatus"
            value={maritalStatus}
          >
            <Grid item id={rbMaritalLabelId} className={classes.radioHeadings}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_5">Marital Status</Aem>
            </Grid>

            {maritalStatusItems.map((item, idx) => {
              const rbMaritalInputId = `${rbMaritalInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  onClick={() => {
                    setMaritalStatus(item?.Key);
                    setMaritalStatusValue(item?.Value);
                  }}
                  className={
                    maritalStatus === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbMaritalInputId}
                    value={item}
                    checked={maritalStatus === item?.Key}
                    onChange={() => {
                      setMaritalStatus(item?.Key);
                      setMaritalStatusValue(item?.Value);
                    }}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbMaritalInputId}
                    className={
                      maritalStatus === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup>

          <RadioGroup
            id={rbRaceGroupId}
            aria-labelledby={rbRaceLabelId}
            name="race"
            value={race}
          >
            <Grid item id={rbRaceLabelId} className={classes.radioHeadings}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_6">Race</Aem>
            </Grid>

            {raceItems.map((item, idx) => {
              const rbRaceInputId = `${rbRaceInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  onClick={() => {
                    setRace(item?.Key);
                    setRaceValue(item?.Value);
                  }}
                  className={
                    race === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbRaceInputId}
                    value={item}
                    checked={race === item?.Key}
                    onChange={() => {
                      setRace(item?.Key);
                      setRaceValue(item?.Value);
                    }}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbRaceInputId}
                    className={
                      race === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup>

          <RadioGroup
            id={rbEthnicityGroupId}
            aria-labelledby={rbEthnicityLabelId}
            name="ethnicity"
            value={ethnicity}
          >
            <Grid item id={rbEthnicityLabelId} className={classes.radioHeadings}>
              <Aem cid="BODY_DEMOGRAPHICS_PATIENT_IDENTIFY_7">Ethnicity</Aem>
            </Grid>

            {ethnicityItems.map((item, idx) => {
              const rbEthnicityInputId = `${rbEthnicityInputIdPrefix}-rb-${idx}`;
              return (
                <Grid
                  key={idx}
                  item
                  onClick={() => {
                    setEthnicity(item?.Key);
                    setEthnicityValue(item?.Value);
                  }}
                  className={
                    ethnicity === item?.Key
                      ? classes.selectedOption
                      : classes.unselectedOption
                  }
                >
                  <Radio
                    id={rbEthnicityInputId}
                    value={item}
                    checked={ethnicity === item?.Key}
                    onChange={() => {
                      setEthnicity(item?.Key);
                      setEthnicityValue(item?.Value);
                    }}
                    classes={{
                      root: classes.radioButton,
                      checked: classes.radioChecked,
                    }}
                  />
                  <FormLabel
                    htmlFor={rbEthnicityInputId}
                    className={
                      ethnicity === item?.Key
                        ? classes.selectedLabel
                        : classes.unselectedLabel
                    }
                  >
                    {item.Value}
                  </FormLabel>
                </Grid>
              );
            })}
          </RadioGroup>
          
          <CustomTextField
            id={religionInputId}
            className={classes.inpBox}
            label={religoiusAffLabel}
            name="religion"
            fullWidth={true}
            variant="standard"
            value={religiousAffValue}
            onClick={(e) => {
              setOverlay(!overlay);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
        }}
       >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={async () => {
              await dispatch(gotoPrevTaskStep());
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          justifySelf="center"
          onClick={() => {
            if (sexAtBirth === "") {
              setError(true);
              if (myRef != null && myRef.current != null) {
                myRef.current.scrollIntoView();
              }
            }
          }}
        >
          <NavButton
            accentColor={"green"}
            disabled={buttonDisabled}
            label={navButtonLabel}
            fullWidth={true}
            trackName={navButtonName}
            trackLocation="nav footer"
            onClick={async () => {
              dispatch(setTaskTransitionPending(true));
              updateRedux();
              await updateDetailsAPI();
              dispatch(gotoNextTaskStep());
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={false}
        open={overlay}
        onClose={() => setOverlay(false)}
        TransitionComponent={Transition}
        aria-label={religoiusAffLabel}
        aria-labelledby=""
        PaperProps={appDialogTransparentPaperProps}
      >
        <OverlayComponent 
          list={religionList} 
          ariaLabel={religoiusAffLabel}
          onChange = {(v)=>addReligion(v)}
          onCancel = {()=>setOverlay(false)} 
        />
      </Dialog>
    </Grid>
  );
}
